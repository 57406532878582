import React from 'react'
import PropTypes from 'prop-types'
import FontAwesome from "../font-awesome";
import './medal.scss'

const Medal = ({ className, position }) => (
  <div className={`medal medal--${position} ${className}`}>
    <FontAwesome className="medal__badge" icon="star" type="fas" />
    <span className="g--font-span--light medal__position">{position}</span>
  </div>
)
 
Medal.defaultProps = {
  className: '',
  position: '',
}

Medal.propTypes = {
  className: PropTypes.string,
  position: PropTypes.string,
}

export default Medal