import React from 'react'
import PropTypes from 'prop-types'
import Card from '../card'
import Statistic from "../statistic";

import './role.scss'

const Role = ({ className, title, minExperience, maxExperience, startingSalary, futureSalary, relevantStrengths, demand }) => (
  <Card className={`role ${className}`}>
    {title && (
      <div>
        <p className="g--margin-none g--font-h4">{title}</p>
        <hr />
      </div>
    )}
    {minExperience || startingSalary || futureSalary || relevantStrengths ? (
      <div>
        {minExperience && <Statistic minExperience={minExperience} maxExperience={maxExperience} />}
        {startingSalary && <Statistic startingSalary={startingSalary} />}
        {futureSalary && <Statistic futureSalary={futureSalary} />}
        {demand && <Statistic demand={demand} />}
        {relevantStrengths && (
          <Statistic relevantStrengths={relevantStrengths} />
        )}
      </div>
    ) : (
      ""
    )}
  </Card>
)
 
Role.defaultProps = {
  className: '',
  title: '',
  minExperience: null,
  maxExperience: null,
  demand: '',
  startingSalary: null,
  futureSalary: null,
  relevantStrengths: null,
}

Role.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  minExperience: PropTypes.number,
  maxExperience: PropTypes.number,
  demand: PropTypes.string,
  startingSalary: PropTypes.number,
  futureSalary: PropTypes.number,
  relevantStrengths: PropTypes.number,
}

export default Role