import React from 'react'
import PropTypes from 'prop-types'
import Container from '../container'
import Button from '../button'

import './header.scss'

const Header = ({
  className,
  title,
  backAction,
  showBackButton,
  shareAction,
  showShareButton
}) => (
  <div className={`header ${className}`}>
    <Container className='header__container'>
      <div className='header__left'>
        {showBackButton && (
          <Button
            className='header__back'
            icon='arrow-left'
            variant='dark'
            Wrapper={'button'}
            wrapperProps={{ onClick: backAction }}
          />
        )}
        {title && <h1 className='g--margin-none g--font-h3--light'>{title}</h1>}
      </div>
      {showShareButton && (
        <Button
          className='header__share'
          icon='share'
          variant='dark'
          Wrapper={'button'}
          wrapperProps={{ onClick: shareAction }}
        />
      )}
    </Container>
  </div>
)

Header.defaultProps = {
  className: '',
  title: 'Career Finder',
  backAction: null,
  showBackButton: true,
  shareAction: null,
  showShareButton: false
}

Header.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  backAction: PropTypes.func,
  showBackButton: PropTypes.bool,
  shareAction: PropTypes.func,
  showShareButton: PropTypes.bool
}

export default Header
