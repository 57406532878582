import React from 'react'
import PropTypes from 'prop-types'
import Card from '../card'

import './answer.scss'

const imageSource = id =>
  `https://hultbusinessschool.eu.qualtrics.com/WRQualtricsControlPanel/Graphic.php?IM=${id}`

const Answer = props => (
  <Card className={`answer ${props.className}`} dataValue={props.text}>
    <input
      className='answer__input'
      id={`choice-${props.id}`}
      type='radio'
      name={props.questionId}
      value={props.text}
    />
    {props.image && (
      <img
        alt={props.image.Display}
        src={imageSource(props.image.ImageLocation)}
        className='answer__icon'
      />
    )}
    <label
      onClick={() => props.onClickAction(props)}
      className='answer__label g--font-body g--margin-none'
      htmlFor={`choice-${props.id}`}
    >
      {props.text}
    </label>
  </Card>
)

Answer.defaultProps = {
  image: null,
  onClickAction: props => console.log('Unhandled Click', props),
  className: ''
}

Answer.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  questionId: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.shape({
    Display: PropTypes.string.isRequired,
    ImageLocation: PropTypes.string.isRequired
  }),
  onClickAction: PropTypes.func,
  className: PropTypes.string
}

export default Answer
