import React from 'react'
import PropTypes from 'prop-types'

import './card.scss'

const Card = ({ onClick, className, children, dataValue }) => (
  <div onClick={onClick} className={`card ${className}`} data-value={dataValue}>
    {children}
  </div>
)

Card.defaultProps = {
  onClick: () => {},
  className: '',
  dataValue: null
}

Card.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  dataValue: PropTypes.string
}

export default Card
