import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Card from '../card'
import Button from '../button'
import FontAwesome from '../font-awesome'

import './course.scss'

class Course extends Component {
  state = {
    isCourseOpen: this.props.isCourseOpen
  }

  // Toggle degree state
  toggleCourse = () =>
    this.setState({ isCourseOpen: !this.state.isCourseOpen }, () => {})

  render() {
    return (
      <Card className='course'>
        <header onClick={this.toggleCourse} className='course__header'>
          <div className='course__header-left'>
            {this.props.salary ? (
              <FontAwesome
                className='course__icon course__icon--salary'
                icon='dollar-sign'
              />
            ) : (
              <FontAwesome
                className='course__icon course__icon--fire'
                icon='fire'
              />
            )}
            <div className='course__title-container'>
              <p className='course__title g--margin-none g--font-body-bold'>
                {this.props.skill}
              </p>
            </div>
            {this.props.salary && (
              <p className='course__salary g--margin-none g--font-body-bold'>
                {Math.round(this.props.salary) < 0 ? '' : '+'}
                {Math.round(this.props.salary)}%
              </p>
            )}
          </div>
          <div className='course__icon-container'>
            <FontAwesome
              className={`course__icon ${
                this.state.isCourseOpen ? 'course__icon--open' : ''
              }`}
              icon='angle-down'
            />
          </div>
        </header>
        <div
          className={`course__content ${
            this.state.isCourseOpen ? 'course__content--open' : ''
          }`}
        >
          <p className={`course__description g--margin-none`}>
            {this.props.desc}
          </p>
          <div className='g--spacer' />
          <a target='_blank' rel='noopener noreferrer' href={this.props.href}>
            <Button
              className='button--tablet-wide'
              variant='cta'
              Wrapper={'div'}
              label='Find online courses that build this skill'
              icon='arrow-right'
            />
          </a>
        </div>
      </Card>
    )
  }
}

Course.propTypes = {
  skill: PropTypes.string,
  salary: PropTypes.string,
  desc: PropTypes.string,
  href: PropTypes.string
}

export default Course
