import React from 'react'
import PropTypes from 'prop-types'
import Container from '../container'

import './footer.scss'

const Footer = ({
  className,
  children
}) => (
  <footer className={`footer ${className}`}>
    <Container className="footer__container container--no-padding">
      <hr className="footer__hr"/>
      {children}
    </Container>
  </footer>
)

Footer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default Footer
