import React from 'react'
import PropTypes from 'prop-types'
import Card from '../card'

import './hult-intro.scss'
import Button from '../button'

const HultIntro = ({ className, title, description }) => (
  <Card className={`hult-intro ${className}`}>
    <iframe 
      className="hult-intro__video" 
      title="Hult video" 
      src="https://www.youtube.com/embed/CgJQObQ0TnQ" 
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      rel="0"
    ></iframe>
    <div className="hult-intro__content">
      <p className="hult-intro__title g--font-h4">{title}</p>
      <p className="hult-intro__description">{description}</p>
      <hr/>
      <Button label="Learn more about Hult" icon="arrow-right" variant="tertiary"/>
    </div>
  </Card>
)
 
HultIntro.defaultProps = {
  className: '',
  title: 'Hult International Business School',
  description: 'At Hult, you’ll learn about the world, the future, and yourself.',
}

HultIntro.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
}

export default HultIntro