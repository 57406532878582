import React from "react";
import PropTypes from "prop-types";
import Card from "../card";
import Medal from "../medal";
import Skill from "../skill";

import "./match-details.scss";

const MatchDetails = ({ 
  className, 
  title,
  matchRating,
  description,
  position,
  transferableSkills,
  personalSkills,
}) => (
  
  <Card className={`match-details ${className}`}>
    <header className="match-details__header">
      <div className="match-details__header-left">
        <Medal className="match-details__medal" position={position} />
        <h3 className="match-details__title g--margin-none">{title}</h3>
      </div>
      <span className="match-details__match-rating g--margin-none">
        {matchRating}% career match
      </span>
    </header>
    <hr />
    {description && (
      <div>
        <p className="g--margin-none match-details__description">
          {description}
        </p>
        <hr />
      </div>
    )}
    {transferableSkills && (
      <div>
        <p className="g--font-h4">Transferable skills</p>
        <p>These will prepare you for whatever your future career holds. Studying Marketing equips you with...</p>
        <div className="row row--flow row--gutters-xsmall">
          {transferableSkills.map(transferableSkill => (
            <div className="row__column">
              <Skill disabled label={transferableSkill} /> 
            </div>
          ))}
        </div>
        <hr />
      </div>
    )}
    {personalSkills && (
      <div>
        <p className="g--font-h4">Personal skills</p>
        <p>You can make the most of your talents in a well-chosen career. In Marketing, you’ll use...</p>
        <div className="row row--flow row--gutters-xsmall">
          {personalSkills.map(personalSkill => (
            <div className="row__column">
              <Skill disabled label={personalSkill} /> 
            </div>
          ))}
        </div>
      </div>
    )}
  </Card>
);

MatchDetails.defaultProps = {
  className: "",
  title: "",
  matchRating: null,
  description: "",
  position: "",
  transferableSkills: [],
  personalSkills: [],
};

MatchDetails.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  matchRating: PropTypes.number,
  description: PropTypes.string,
  position: PropTypes.string,
  transferableSkills: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  personalSkills: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export default MatchDetails;
