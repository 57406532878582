import React, { Component } from "react";
import PropTypes from "prop-types";
import Card from "../card";

import "./degree.scss";
import FontAwesome from "../font-awesome";

class Degree extends Component {
	state = {
		isDegreeOpen: this.props.isDegreeOpen
	};

	// Toggle degree state
	toggleDegree = () =>
    this.setState({ isDegreeOpen: !this.state.isDegreeOpen }, () => {});
    
  getIcon() {
    if (this.props.type === 'Major'){
      return 'graduation-cap';
    }
    if (this.props.type === 'Minor'){
      return 'books';
    }
  }

	render() {
		return (
			<Card className={`degree`}>
				<header onClick={this.toggleDegree} className="degree__header">
          <div className="degree__header-left">
            <FontAwesome className="degree__icon" icon={this.getIcon()} />
            <p className="degree__title g--margin-none g--font-body-bold">
              {this.props.type} in {this.props.title}
            </p>
          </div>
          <FontAwesome className={`degree__icon ${this.state.isDegreeOpen ? 'degree__icon--open' : ''}`} icon="angle-down" />
				</header>
				<div
					className={`degree__content ${
						this.state.isDegreeOpen ? "degree__content--open" : ""
					}`}
				>
					<p className={`degree__description g--margin-none`}>
						{this.props.description}
					</p>
				</div>
			</Card>
		);
	}
}

Degree.defaultProps = {
	className: "",
	toggleDegree: () => {},
  isDegreeOpen: false,
	title: "",
  type: "",
  description: "",
};

Degree.propTypes = {
	className: PropTypes.string,
	toggleDegree: PropTypes.func,
  isDegreeOpen: PropTypes.bool,
	title: PropTypes.string,
  type: PropTypes.string,
  description: PropTypes.string,
};

export default Degree;
