import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FontAwesome from '../font-awesome'

import './statistic.scss'

// const Statistic = ({ icon, type, salary }) => (
class Statistic extends Component {

  getIcon() {
    if (this.props.relevantStrengths){
      return 'brain';
    }
    if (this.props.demand){
      return 'chart-line';
    }
    if (this.props.minExperience || this.props.maxExperience){
      return 'clock';
    }
    if (this.props.startingSalary){
      return 'money-bill-alt';
    }
    if (this.props.futureSalary){
      return 'sack-dollar';
    }
  }

  render() {
    let type = this.props.type;
    const startingSalary = this.props.startingSalary;
    const futureSalary = this.props.futureSalary;
    const minExperience = this.props.minExperience;
    const maxExperience = this.props.maxExperience;
    const demand = this.props.demand.charAt(0).toUpperCase() + this.props.demand.slice(1)
    const relevantStrengths = this.props.relevantStrengths;

    // Set types (positive | negative | neutral | strengths)
    if (demand === 'Low'){
      type = 'negative';
    }
    else if (demand === 'High' || demand === 'Very High' || startingSalary || futureSalary){
      type = 'positive';
    }
    else if (demand === 'Medium') {
      type = 'neutral';
    }
    else if (relevantStrengths) {
      type = 'strengths';
    }

    return (
      <div className={`statistic ${type ? 'statistic--' + type : ''}`}>
        <div className="statistic__icon">
          <FontAwesome icon={this.getIcon()} />
        </div>
        {startingSalary && (
          <p className="statistic__copy g--margin-none g--font-body-bold">${startingSalary}k potential starting salary</p>
        )}
        {futureSalary && (
          <p className="statistic__copy g--margin-none g--font-body-bold">${futureSalary}k potential starting salary</p>
        )}
        {relevantStrengths && (
          <p className="statistic__copy g--margin-none g--font-body-bold">You have {relevantStrengths} of the strengths to
          succeed</p>
        )}
        {demand && (
          <p className="statistic__copy g--margin-none g--font-body-bold">{demand} market demand</p>
        )}
        {minExperience && (
          <p className="statistic__copy g--margin-none g--font-body-bold">{minExperience}{maxExperience ? ' - ' : ''}{maxExperience} years experience</p>
        )}
      </div>
    )
  }
}
 
Statistic.defaultProps = {
  icon: '',
  startingSalary: '',
  futureSalary: '',
  minExperience: null,
  maxExperience: null,
  type: '',
  category: '',
  relevantStrengths: '',
  demand: '',
}

Statistic.propTypes = {
  icon: PropTypes.string,
  startingsalary: PropTypes.string,
  futureSalary: PropTypes.string,
  minExperience: PropTypes.number,
  maxExperience: PropTypes.number,
  type: PropTypes.string,
  category: PropTypes.string,
  relevantStrengths: PropTypes.string,
  demand: PropTypes.string,
}

export default Statistic