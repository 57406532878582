import React, { Component } from 'react'
import PropTypes from "prop-types";
import Card from "../card";
import Message from "../message";
import Statistic from "../statistic";
import Button from "../button";
import Medal from "../medal";
import "./career-match.scss";

class CareerMatch extends Component {

  truncate() {
    return this.props.description.split(" ").splice(0, 14).join(" ");
  }

  render() {
    return (
      <Card className={`career-match ${this.props.className}`}>
        <header className="career-match__header">
          <div className="career-match__header-left">
            <Medal className="career-match__medal" position={this.props.position} />
            <h3 className="career-match__title g--margin-none">{this.props.title}</h3>
          </div>
          <span className="career-match__match-rating g--margin-none">
            {this.props.matchRating}% career match
          </span>
        </header>
        <hr />
        {this.props.description && (
          <div>
            <p className="g--margin-none career-match__description">
              {this.truncate()}...
              <b className="career-match__read-more"> continue reading</b>
            </p>
            <hr />
          </div>
        )}
        {this.props.message && (
          <div>
            <Message avatar={this.props.avatar} message={this.props.message} />
            <hr />
          </div>
        )}
        {this.props.startingSalary || this.props.futureSalary || this.props.relevantStrengths ? (
          <div>
            {this.props.startingSalary && <Statistic startingSalary={this.props.startingSalary} />}
            {this.props.futureSalary && <Statistic futureSalary={this.props.futureSalary} />}
            {this.props.relevantStrengths && (
              <Statistic relevantStrengths={this.props.relevantStrengths} />
            )}
            <hr />
          </div>
        ) : (
          ""
        )}
        <Button label={`Read more ${this.props.title} stats`} icon="arrow-right" />
      </Card>
    );
  }
}

CareerMatch.defaultProps = {
  className: "",
  title: "",
  matchRating: null,
  description: "",
  position: "",
  avatar: "",
  message: "",
  startingSalary: null,
  futureSalary: null,
  relevantStrengths: null
};

CareerMatch.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  matchRating: PropTypes.number,
  description: PropTypes.string,
  position: PropTypes.string,
  avatar: PropTypes.string,
  message: PropTypes.string,
  startingSalary: PropTypes.number,
  futureSalary: PropTypes.number,
  relevantStrengths: PropTypes.number
};

export default CareerMatch;
