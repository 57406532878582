import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './skill.scss'

class Skill extends Component {
  state = {
    isSelected: this.props.isSelected
  }

  toggle = () =>
    !this.props.disableTogle &&
    this.setState({ isSelected: !this.state.isSelected }, () => {
      const { onSkillSelect } = this.props
      onSkillSelect(this.props.title, this.state.isSelected)
    })

  render() {
    const skillClass = ['skill']
    if (this.state.isSelected) {
      skillClass.push('skill--selected')
    }
    return (
      <div onClick={this.toggle} className={skillClass.join(' ')}>
        <p className="skill__label g--margin-none g--font-body-bold">{this.props.title}</p>
      </div>
    )
  }
}

Skill.defaultProps = {
  onSkillSelect: () => {},
  isSelected: false,
  disableTogle: false
}

Skill.propTypes = {
  title: PropTypes.string.isRequired,
  onSkillSelect: PropTypes.func,
  disableTogle: PropTypes.bool,
  isSelected: PropTypes.bool
}

export default Skill
